<template>
  <div class="">
    <div class="text-general">
      <div class="p-2">
        <label class="header-text font-weight-bold">สร้างรายรายการขาย</label>
        <div class="d-flex justify-content-between mt-2">
          <p class="font-weight-bold">รหัสพนักงาน</p>
          <p class="text-gray-2">{{ salesNo }}</p>
        </div>
      </div>
      <div class="mt-3 bg-gray-box p-2">
        <InputText
          textFloat="เบอร์โทรศัพท์"
          v-model="form.telephone"
          class="mb-3"
          placeholder="เบอร์โทรศัพท์"
          type="number"
          name="telephone"
          isRequired
          :isValidate="$v.form.telephone.$error"
          :v="$v.form.telephone"
          @onKeypress="isNumber($event)"
        />
        <InputText
          textFloat="หมายเลขบิล"
          v-model="form.ticket_no"
          placeholder="หมายเลขบิล"
          type="text"
          name="ticket_no"
          isRequired
          :isValidate="$v.form.ticket_no.$error"
          :v="$v.form.ticket_no"
          @onKeypress="isNumber($event)"
        />
      </div>
      <div class="footer-page px-5">
        <b-button
          class="btn-link"
          block
          :disabled="isLoading"
          @click="resetForm"
          >ล้างข้อมูล
        </b-button>
        <b-button
          class="radius-btn bg-main"
          block
          :disabled="isLoading"
          @click="submitForm"
          >ยืนยัน
        </b-button>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "CustomerIndex",
  components: {
    InputText,
    ModalLoading,
  },
  computed: {
    ...mapGetters({
      salesNo: "getSalesNo",
      branchId: "getBranchId",
    }),
  },
  validations() {
    return {
      form: {
        ticket_no: { required, minLength: minLength(9) },
        telephone: { required, minLength: minLength(10) },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      form: {
        sales_no: "",
        branch_id: "",
        ticket_no: "",
        telephone: "",
      },
    };
  },
  watch: {
    "form.telephone"(val) {
      if (val.length >= 10) {
        setTimeout(() => {
          this.form.telephone = val.substring(0, 10);
        }, 100);
      }
    },
    "form.ticket_no"(val) {
      if (val.length >= 9) {
        setTimeout(() => {
          this.form.ticket_no = val.substring(0, 9);
        }, 100);
      }
    },
  },
  async created() {
    this.form.sales_no = this.salesNo;
    this.form.branch_id = this.branchId;
  },
  mounted() {
    this.$store.dispatch("setSalesNo", this.$cookies.get("sales_no"));
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetForm() {
      this.form.telephone = "";
      this.form.ticket_no = "";
    },
    submitForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      await this.$store.dispatch("setMainLoading", true);

      this.form.sales_no = this.salesNo;

      await this.axios
        .post(`${this.$baseUrlSaleOrder}/saleOrder`, this.form)
        .then(async (data) => {
          this.isDisable = false;
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.$router.push({ path: `/sale-order` }).catch(() => {});
          } else {
            this.isLoading = false;
            this.isLogin = false;
            this.error = data.message;
            this.warningAlert(this.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          this.warningAlert(error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-link {
  border-color: transparent !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}
.footer-page {
  width: 100%;
  position: fixed;
  bottom: 60px;
  left: 0;
}
</style>
